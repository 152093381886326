<template>
  <GameLists
    :getList="getGameSearch"
    :itemsPerScreen="itemsPerScreen"
    :hasVericalUpperRow="true"
    :slug="query"
  />
</template>
<script>
import { mapState } from 'vuex'
import { isEmptyOrNil } from '@/helpers'
import GameLists from '@/components/GameLists'
import { GameApi } from '@/api'
import { cardsPerScreenCalculationMixin } from '@/mixins'
import {
  EMPTY_STRING,
  SEARCH_RESULT,
  Module,
  CURRENT_LOCATION,
} from '@/constants'

export default {
  name: SEARCH_RESULT,
  mixins: [cardsPerScreenCalculationMixin],
  data: () => ({
    query: EMPTY_STRING,
  }),
  components: {
    GameLists,
  },
  computed: {
    ...mapState(Module.LOCATION, [CURRENT_LOCATION]),
  },
  methods: {
    getGameSearch(args) {
      return GameApi.getGameSearch({ ...args, ...this.query })
    },
  },
  watch: {
    CURRENT_LOCATION: {
      deep: true,
      immediate: true,
      async handler(location) {
        if (isEmptyOrNil(location)) {
          return
        }

        this.query = location?.query
      },
    },
  },
}
</script>
